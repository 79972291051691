.footer {
    width: 100%;
    height: 72px;

    .ul {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        height: 72px;
        padding: 8px 0 24px;
        justify-content: space-around;
        background-color: #0a0a0a;
        z-index: 100;

        @media (width >= 513px) {
            width: 512px;
            left: 50%;
            transform: translateX(-50%);
        }

        .li {
            color: #5f5f5f;
            text-align: center;
            cursor: pointer;
            width: 100%;

            .icon {
                width: 24px;
                height: 24px;
            }

            .link {
                position: relative;
                display: block;
                width: 100%;
                color: #5f5f5f;

                .pointer {
                    position: absolute;
                    top: 0;
                    background-color: #d20202;
                    border-radius: 99em;
                    color: #fff;
                    padding: 4px;
                    font-size: 12px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    width: 20px;
                    transform: translateX(16px);
                }
            }

            .text {
                user-select: none;
            }

            .active {
                font-weight: 700;
                color: #d4d4d4;
            }
        }
    }
}
