.teenagerWarning {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 288px;
    box-sizing: border-box;
    padding: 16px 24px;
    background-color: #262626;
    z-index: 100;
    border-radius: 16px;
    outline: none;
    box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 80%);

    .closeIcon {
        position: absolute;
        top: 16px;
        right: 16px;
    }

    .logo {
        text-align: center;
    }

    .text {
        text-align: center;
        margin: 16px 0;

        .title {
            font-size: 16px;
            font-weight: 700;
            line-height: 18px;
            color: #d4d4d4;
            margin-bottom: 8px;
        }

        .content {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #737373;
        }
    }

    .btn {
        font-size: 14px;
        margin: 16px 0;
        font-weight: 700;
    }
}
