.rechargeBox {
    display: flex;
    padding: 24px 16px;
    align-items: center;
    background-color: #171717;
    border-radius: 16px;

    .diamondInfo {
        display: flex;
        align-items: center;
        gap: 8px;

        .diamondCount {
            font-size: 24px;
            font-weight: 700;
            line-height: 36px;
            color: #d4d4d4;
        }
    }

    .rechargeButton {
        margin-left: auto;
    }
}
