@import 'https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100..900&display=swap';
@import 'swiper/css';
@import 'swiper/css/pagination';

.rootStyle {
    width: 100%;
    margin: 0;
    font-weight: 400;
    scroll-behavior: smooth;
}

body * ::-webkit-scrollbar {
    display: none;
}

body {
    -ms-overflow-style: none;
    scrollbar-width: none;
    box-sizing: border-box;
    line-height: normal;
    background: #171717;
    color: #fff;
}

.MuiDrawer-paper {
    background: none;
}

.MuiSnackbar-root {
    left: 16px;
    right: 16px;
    bottom: 16px;

    @media (width >= 513px) {
        left: 50%;
    }
}

.MuiSnackbarContent-root {
    font-size: 14px;
    font-weight: 400;
    color: #d4d4d4;
    border-radius: 8px;
    backdrop-filter: blur(2px);
}

// swiper style
.swiper-pagination {
    .swiper-pagination-bullet {
        background: rgb(0 0 0 / 50%);
        opacity: initial;

        &.swiper-pagination-bullet-active {
            background: #171717;
        }
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
