.pwaTip {
    background-color: #262626;
    display: flex;
    gap: 4px;
    padding: 8px;
    align-items: center;
    color: #d4d4d4;

    .close {
        color: #525252;
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;

        .text {
            color: #d4d4d4;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
        }

        .cta {
            border-radius: 12px;
            border: 1px #d4d4d4 solid;
            padding: 6px 12px;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            margin-right: 8px;
        }
    }
}

.pwaTipBottom {
    position: fixed;
    bottom: 8px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;

    .box {
        border-radius: 16px;
        background: rgb(92 92 92 / 60%);
        backdrop-filter: blur(8px);
        padding: 16px;
        width: calc(100% - 32px);
        margin: auto;
        box-sizing: border-box;
        max-width: 360px;

        .top {
            position: relative;
            color: #d4d4d4;
            height: 24px;
            line-height: 24px;
            margin-bottom: 8px;

            .title {
                font-size: 16px;
                font-weight: 700;
                text-align: center;
                color: #fafafa;
            }

            .close {
                position: absolute;
                top: 0;
                right: 0;
                color: #d4d4d4;
            }
        }

        .cover {
            text-align: center;
        }

        .content {
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            padding: 8px 0 24px;
        }

        .steps {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 8px;

            .stepBox {
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                width: calc((100% - 40px) / 3);

                .step {
                    width: 84px;
                    height: 84px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: auto;
                    margin-bottom: 8px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .text {
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .isOther {
            .stepBox {
                .step {
                    width: 48px;
                    height: 48px;
                }
            }
        }

        .success {
            font-size: 16px;
            font-weight: 700;
            color: #fafafa;
            text-align: center;
            padding: 4px 0;
        }
    }
}

.hidden {
    display: none;
}
